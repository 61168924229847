@media only screen and (max-width: 600px) {
  body {
    overflow: auto;
  }

  .mainContainer {
    height: auto !important;

    .uniqueHeader {
      flex-direction: column;
      height: auto !important;
      padding: 0 !important;
      align-items: center;

      .stepsByStep {
        margin: 10px 0 20px 10px;

        .stepsIndicators {
          .lineStepIndicator {
            width: 30px !important;
          }
        }
      }

      .headerLogo {
        width: 50%;
        margin-top: 30px;
      }
    }

    .bodyContainer {
      height: auto;
      align-items: center;
      background: #f3f3f3;
      padding: 20px 0;

      .formCardCustom {
        width: 92%;
        border-radius: 10px;
        overflow: auto;
        padding: 20px;

        .mobileContent {
          display: flex;
          flex-direction: column;

          .bg-grey {
            background-color: #b9b9b9 !important;
          }

          .btnAddEquipament {
            background-color: #023a86;
            color: white;
            border: none;
            border-radius: 5px;
            padding: 10px 30px;
            margin-top: 10px;
          }

          .labelPlans {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
            margin-top: 40px;

            p {
              margin: 0;
              padding: 0;
              font-size: 12px;
              color: $primary;
              font-weight: bold;
            }
          }

          .groupOfInfos {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;

            .infoItem {
              margin: 10px 0;
              width: 50%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;

              p {
                margin: 0;
                padding: 0;
                font-weight: 500;
                font-size: 12px;
              }

              span {
                font-size: 10px;
                color: $primary;
                font-weight: bold;
              }

              &.spSize {
                width: 100%;
              }
            }
          }

          .plansGroup {
            display: flex;
            flex-direction: column;

            .planItem {
              display: flex;
              flex-direction: column;
              border-bottom: 1px solid #f4f4f4;
              width: 100%;
              justify-content: space-between;
              margin: 0 0 10px 0;

              &:first-of-type {
                .bodyPlanItem {
                  border: none;
                }
              }

              .headerPlanItem {
                display: flex;
                justify-content: space-between;
                align-items: center;
                background: $primary;
                padding: 10px;

                p {
                  font-weight: bold;
                  margin: 0;
                  padding: 0;
                  font-size: 12px;
                  color: #fff;
                }
              }

              .bodyPlanItem {
                padding: 10px;
                border-top: 1px solid #f4f4f4;

                p {
                  margin: 0;
                  padding: 0;
                  font-size: 12px;
                }
              }
            }

            .itemTotal {
              display: flex;
              align-items: center;
              justify-content: space-between;
              border-top: 1px solid #f4f4f4;
              border-bottom: 1px solid #f4f4f4;
              margin: 0 0 50px 0;
              padding: 20px 10px;
              color: $primary;

              p {
                margin: 0;
                padding: 0;
              }

              span {
                font-weight: bold;
              }
            }
          }

          .divTerms {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 0 50px 0;

            label {
              margin: 0;
              padding: 0;
            }

            input {
              margin-right: 10px;
            }
          }

          .separator {
            width: 100%;
            height: 2px;
            background: #c4c4c4;
            margin: 30px 0;
          }

          &.successScrenn {
            height: 100%;
            align-items: center;
            justify-content: center;

            h1 {
              margin-bottom: 10px;
            }

            p {
              margin: 0;
              padding: 0;
              text-align: center;
              margin-bottom: 10px;
              font-size: 13px;
              font-weight: 600;
            }

            span {
              color: $primary;
              font-weight: bold;
            }
          }

          h1 {
            text-align: center;
            font-size: 2.3em;
            margin-bottom: 50px;
            display: flex;
            align-items: center;
            justify-content: center;

            &.resumoTitle {
              font-size: 1.7em;
              margin-top: 20px;
            }
          }

          .toastValue {
            padding: 10px;
            background: #f2f2f2;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border-radius: 8px;
            margin-bottom: 50px;

            p {
              margin: 0;
              padding: 0;
              color: $primary;
              font-size: 15px;
            }

            span {
              color: $primary;
              font-weight: bold;
            }
          }

          .paymentInfos {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;

            button {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              width: 48%;
              background: transparent;
              border: 1px solid #eeeeee;
              padding: 20px 0;
              border-radius: 8px;
              outline: none;

              &:first-of-type {
                background: $primary;

                p {
                  color: #fff;
                }

                span {
                  color: #fff;
                }
              }

              p {
                margin: 0;
                padding: 0;
                font-size: 12px;
              }

              span {
                color: $primary;
                font-weight: bold;
                font-size: 12px;
              }
            }
          }
          .paymentInfosButtom {
            display: flex;
            justify-content: space-between;
            margin-bottom: 100px;

            button {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              width: 100%;
              background: transparent;
              border: 1px solid #eeeeee;
              padding: 20px 0;
              border-radius: 8px;
              outline: none;

              &:first-of-type {
                background: $primary;

                p {
                  color: #fff;
                }

                span {
                  color: #fff;
                }
              }

              p {
                margin: 0;
                padding: 0;
                font-size: 12px;
              }

              span {
                color: $primary;
                font-weight: bold;
                font-size: 12px;
              }
            }
          }
        }

        h1 {
          text-align: center;
          color: $primary;
          margin-bottom: 20px;
          font-size: 2em;
        }

        .footer {
          position: relative;
          padding: 0;

          .advanceDiv {
            width: 100%;

            button {
              width: 100%;
            }

            .advanceBtn {
              &.finalLvl {
                background: transparent !important;
                color: #000;
              }
            }
          }
        }
      }
    }
  }
}
