.mainContainer {
  width: 100%;
  height: 100vh;
  background: #f3f3f3;
  display: flex;
  flex-direction: column;

  nav {
    width: 100%;
    background: #fff;
    padding: 20px 80px;

    svg {
      width: 13%;
    }
  }

  .bodyContainer {
    width: 100%;
    height: 90%;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    .cardCustom {
      width: 85%;
      height: 95%;
      background: #fff;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      display: flex;

      .leftSide {
        width: 40%;
        height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;

        &.resetPassSide {
          padding: 100px 0 0 70px;
        }

        h1 {
          font-size: 4.5em;
          color: #023a86;
        }

        p {
          font-size: 0.8em;
          font-weight: 600;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-top-left-radius: 10px;
        }
      }

      .rightSide {
        width: 60%;
        height: 85%;
        padding: 0 75px;

        display: flex;
        flex-direction: column;
        justify-content: center;

        .headerForm {
          margin: 0 0 40px 0;

          h1 {
            font-size: 4em;
            color: #023a86;
            padding: 0;
            margin: 0;
          }
          p {
            font-size: 2.5em;
          }
        }

        .formGroup {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          margin-bottom: 50px;

          .group {
            display: flex;
            flex-direction: column;
            position: relative;
            margin-top: 10px;

            label {
              font-size: 12px;
              font-weight: bold;
              margin-left: 10px;
            }

            .viewPass {
              position: absolute;
              right: 10px;
              bottom: 13px;
              width: 30px;
              background: transparent;
              border: none;
              cursor: pointer;
              outline: none;
            }
          }

          .forgetPass {
            a {
              float: right;
              text-decoration: underline;
              color: #023a86;
              font-size: 12px;
              margin-top: 20px;
              font-weight: 600;
            }
          }
        }

        .submitBtn {
          button {
            outline: none;
            width: 100%;
            padding: 20px 0;
            border: none;
            border-radius: 8px;
            background: #023a86;
            color: #fff;
            cursor: pointer;
          }

          .outline {
            border: 2px solid #023a86;
            background: transparent;
            color: #023a86;
          }
        }
      }
    }
  }
}
