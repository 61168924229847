@media only screen and (max-width: 600px) {
  .footer {
    div {
      .regressDiv {
        width: 100% !important;
        margin-top: 20px;

        button {
          width: 100% !important;
        }
      }
      display: flex;
      flex-direction: column-reverse;
    }
  }
}
