.end {
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow-x: hidden;

  .separator {
    width: 90%;
    background-color: #f7f7f7;
    height: 1px;
  }

  .wrapper_text {
    display: flex;
    align-self: stretch;
    align-items: center;
    background: white;
    flex-direction: column;
    justify-content: center;
  }

  .btn {
    border-radius: 30px;
    font-size: 12px !important;
    padding: 10px 40px;
  }
}
