.uniqueHeader {
  display: flex;
  max-width: 100%;
  height: 10%;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  padding: 20px 100px;

  .headerLogo {
    svg {
      max-width: 50%;
      margin-right: 10px;
    }
    img {
      max-width: 50%;
      max-height: 35px;
    }
  }

  .stepsByStep {
    display: flex;
    flex-direction: column;

    span {
      color: #023a86;
      font-weight: bold;
      margin-bottom: 10px;
    }

    .stepsIndicators {
      display: flex;

      .lineStepIndicator {
        width: 40px;
        height: 5px;
        border-radius: 5px;
        background: #f3f3f3;
        margin-right: 10px;

        .activateStep {
          border-radius: 5px;
          width: 100%;
          height: 100%;
          background: #e3e829;
        }
      }
    }
  }
}
