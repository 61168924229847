.overflow {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.cantAdvance {
  filter: grayscale(0.5);
  cursor: not-allowed !important;
}

body {
  overflow: hidden;
}

.spinner {
  animation: rotate 2s linear infinite;
  z-index: 2;

  margin: -10px 0 0 -15px;
  width: 30px;
  height: 30px;

  & .path {
    stroke: hsl(210, 70, 75);
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

.formCardCustom {
  width: 85%;
  height: 95%;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  display: flex;
  flex-direction: column;
  background: #fff;
  position: relative;
  outline: none;

  .formStep {
    outline: none;
    height: auto;

    .controlForms {
      outline: none;
      height: 100%;
      display: flex;

      .leftSide {
        width: 40%;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 10px 0 0 70px;

        textarea {
          border: 1px solid #e6e6e6;
          border-radius: 8px;
          padding: 10px;
          outline: none;
          resize: none;
        }

        h3 {
          font-size: 16px;
          margin-bottom: 20px;
          font-weight: bold;
        }

        .ticketForm {
          display: flex;
          flex-direction: column;
          width: 100%;
          margin-top: 50px;

          .codeInputLabel {
            margin-left: 10px;
            font-weight: bold;
          }

          .codeInputDiv {
            display: flex;
            justify-content: space-between;
            border: 1px solid #e6e6e6;
            padding: 5px;
            border-radius: 8px;

            .codeInp {
              outline: none;
              width: 80%;
              border: none;
              text-indent: 10px;
              padding: 5px 0;
              margin-right: 5px;
              text-transform: uppercase;
            }

            button {
              width: 20%;
              cursor: pointer;
              border: none;
              outline: none;
              border-radius: 8px;
              background: $primary;
              color: #fff;
            }
          }

          .controlResponse {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 20px 0;

            .loadingDiv {
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 30px 0;
            }

            .ticketDiv {
              position: relative;

              .ticketInfos {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                p {
                  margin: 0 0 5px 0;
                  padding: 0;
                  font-size: 16px;
                }
              }
            }
          }
        }

        .controlResponse {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
        }

        .planToShow {
          display: flex;
          flex-direction: column;
          width: 100%;
          border: 1px solid #e6e6e6;
          border-radius: 8px;

          .planToShowHeader {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 15px;
            background: #023a86;
            color: #fff;
            border-top-right-radius: 8px;
            border-top-left-radius: 8px;

            p {
              margin: 0;
              padding: 0;

              &:first-of-type {
                width: 80%;
              }

              &:last-of-type {
                width: 20%;
                font-size: 13px;
                text-align: right;
              }
            }
          }

          .planToShowBody {
            display: flex;
            flex-direction: column;
            padding: 15px;

            .planItem {
              display: flex;
              flex-direction: column;

              span {
                margin-left: 5px;
                font-weight: bold;
                margin-bottom: 5px;
                font-size: 15px;
              }

              p {
                margin: 0;
                padding: 0;
                margin-bottom: 10px;
              }
            }
          }
        }

        h1 {
          font-size: 4em;
          color: #023a86;
        }

        p {
          font-size: 0.8em;
          font-weight: 600;
        }
      }

      .rightSide {
        padding: 10px 70px 0 50px;
        width: 60%;
        height: 100%;
        overflow-x: auto;

        .btnAddEquipament {
          background-color: #023a86;
          color: white;
          border: none;
          border-radius: 5px;
          padding: 10px 30px;
          margin-top: 10px;
          float: right;
        }

        .equipaments {
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          border: 2px solid #c1c1c1;

          .header {
            background-color: #0033a0;
            border: 2px solid #0033a0;
            padding: 15px 30px;
            margin: -2px 0 0 -2px;

            p {
              color: white;
              font-weight: 600;
              padding: 0;
              margin: 0;
            }
          }

          .body {
            display: flex;
            flex-direction: column;
            padding: 30px;

            p {
              padding: 0;
              margin: 0;
            }
          }
        }

        .btnRemoveEquipament {
          border: none;
          background: none;
          color: red;
          text-decoration: underline;
        }

        .btnUpdateEquipament {
          border: none;
          background: none;
          color: #0033a0;
          text-decoration: underline;
        }

        .wrapperBikeDiscount {
          clear: both;
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-top: 80px;

          p {
            margin: 0;
            margin-left: 10px;
            font-size: 14px;
          }
        }

        h1 {
          margin-bottom: 50px;
        }

        .controlPlans {
          min-width: 350px;
          display: flex;
          flex-direction: column;
          border: 1px solid #e6e6e6;
          border-radius: 8px;

          .plansHeaderFixed {
            width: 100%;
            padding: 20px;
            background: $primary;
            color: #fff;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            display: flex;
            justify-content: space-between;

            p {
              margin: 0;
              padding: 0;
            }

            .p__one {
              width: 50%;
            }

            .p__one_phone {
              width: 25%;
            }

            .p__two {
              width: 25%;
              text-align: center;
            }

            .p__tree {
              width: 25%;
              text-align: right;
            }
          }

          .planItemControl {
            padding: 10px 20px;
            display: flex;
            justify-content: space-between;

            .planItemName {
              width: 50%;

              p {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-weight: bold;
              }
            }

            .planItemName_phone {
              width: 25%;

              p {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-weight: bold;
              }
            }

            .planItemContractValue {
              width: 25%;

              p {
                text-align: center;
                font-weight: normal;
                font-size: 15px;
                color: #000;
              }
            }

            .planItemFinalValue {
              width: 35%;
              position: relative;

              span {
                font-size: 10px;
                text-decoration: line-through;
                margin-right: 5px;
                position: absolute;
                bottom: 80%;
                right: 0;
              }

              p {
                font-weight: bold;
                text-align: right;
              }
            }

            p {
              margin: 0;
              padding: 0;
            }
          }

          .totalPlansDiv {
            display: flex;
            justify-content: space-between;
            padding: 20px;
            border-top: 1px solid #e6e6e6;

            p {
              margin: 0;
              padding: 0;
              font-weight: bold;
            }
          }

          .totalPlansTicket {
            display: flex;
            justify-content: space-between;
            padding: 5px 20px;

            p {
              margin: 0;
              padding: 0;
              font-weight: bold;
              font-size: 13px;
            }

            span {
              font-size: 13px;
            }
          }

          .totalPlansTicketWithCalc {
            display: flex;
            justify-content: flex-end;
            padding: 5px 20px;
            background: $primary;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;

            span {
              color: #fff;
              font-size: 13px;
            }
          }
        }

        .successDiv {
          width: 100%;
          padding: 20px 0;
          display: flex;
          flex-direction: column;
          background: #ebebeb;
          align-items: center;
          justify-content: center;
          border-radius: 8px;
          margin-bottom: 20px;

          div {
            display: flex;
            align-items: center;

            &:first-of-type {
              margin-bottom: 10px;
            }

            p {
              margin: 0;
              padding: 0;
            }

            &.successText {
              color: #34619e;
            }

            &.successLink {
              font-size: 12px;

              p {
                color: #c3c3c3;
              }

              button {
                padding: 0;
                margin: 0;
                border: none;
                background: transparent;
                margin-left: 20px;
                cursor: pointer;
                outline: none;

                svg {
                  transition: 0.3s;

                  &:active {
                    transform: scale(0.95);
                  }
                }
              }
            }
          }
        }

        .successBtns {
          display: flex;
          width: 100%;
          align-items: center;
          justify-content: space-between;

          button {
            outline: none;
            width: 49%;
            height: 130px;
            background: transparent;
            border: 1px solid #ebebeb;
            border-radius: 8px;
            cursor: pointer;
            transition: 0.3s;

            &:hover {
              background: #fbfbfb;
            }

            p {
              margin: 8px 0 0 0;
              padding: 0;
              color: #34619e;
            }

            span {
              color: #34619e;
            }
          }
        }

        .informationDiv {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 20px 0 0 0;

          p {
            margin: 0;
            color: #f00;
            padding: 0;
          }
        }

        .controlPlansOffered {
          display: flex;
          width: 100%;

          .plansOffered {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%;

            .plan {
              cursor: pointer;
              width: 49%;
              height: 100px;
              display: flex;
              align-items: center;
              margin-bottom: 20px;
              transition: 0.3s;

              &.nowUSee {
                background: #f8f8f8;
              }

              .checkPlan {
                width: 20%;
                height: 100%;
                background: #023a86;
                border: 1px solid #023a86;
                display: flex;
                align-items: center;
                justify-content: center;

                .radioToCheck {
                  margin: 0;
                  padding: 0;
                  width: 25px;
                  height: 25px;
                  border: 2px solid #fff;
                  border-radius: 50%;
                  position: relative;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  padding: 1px;

                  i {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    background: #fff;
                  }
                }
              }

              .planContainer {
                width: 80%;
                height: 100%;
                // padding: 10px;
                border: 1px solid #e6e6e6;
                border-left: none;

                .planHeader {
                  height: 30%;
                  display: flex;
                  justify-content: space-between;
                  border-bottom: 1px solid #e6e6e6;
                  padding: 0 10px;

                  p {
                    margin: 0;
                    padding: 0;
                    font-size: 15px;
                    font-weight: bold;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
                }

                .planDescription {
                  overflow: hidden;
                  height: 75%;
                  padding: 10px;

                  p {
                    margin: 0;
                    padding: 0;
                    font-size: 12px;
                    font-weight: 600;
                  }
                }
              }
            }
          }
        }

        .totalValue {
          display: flex;
          width: 100%;
          justify-content: space-between;
          padding: 10px;
          border-top: 1px solid #000;
          margin-top: 20px;

          p {
            margin: 0;
            padding: 0;
            font-weight: bold;
          }
        }

        .formGroup {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;

          .especialGroup {
            margin-top: 10px;

            .especialFields {
              display: flex;

              .fields {
                width: 50%;
                display: flex;
                flex-direction: column;
                position: relative;

                &:first-of-type {
                  margin-right: 30px;
                }

                .radioFields {
                  display: flex;
                  align-items: center;
                  padding: 15px 0;

                  input[type='radio'] {
                    margin-right: 10px;
                  }

                  label {
                    margin: 0;
                    padding: 0;
                    font-size: 12px;
                    font-weight: 600;

                    &:first-of-type {
                      margin-right: 50px;
                    }
                  }
                }

                .especialLabel {
                  font-size: 12px;
                  font-weight: bold;
                  margin-left: 10px;
                }
              }
            }

            .alertField {
              display: flex;
              padding: 20px 0 0 0;
              align-items: center;

              p {
                margin: 0;
                padding: 0;
                color: #5a5a5a;
                font-weight: bold;
              }
            }
          }

          .rowGroup {
            display: flex;
            justify-content: space-between;

            .mnSize {
              width: 19%;
            }

            .spSize {
              width: 79%;
            }
          }

          .group {
            display: flex;
            flex-direction: column;
            position: relative;
            margin-top: 10px;

            label {
              font-size: 12px;
              font-weight: bold;
              margin-left: 10px;
            }
          }

          .radioGroup {
            display: flex;
            flex-direction: column;
            padding: 10px 0 0 10px;

            .radioHeader {
              p {
                margin: 0 0 10px 0;
                padding: 0;
                font-weight: bold;
                font-size: 12px;
              }
            }

            .radioFields {
              display: flex;
              align-items: center;

              input {
                margin-right: 10px;
                cursor: pointer;
                position: relative;
              }

              label {
                margin: 0;
                padding: 0;
                font-weight: 600;
                cursor: pointer;

                &:first-of-type {
                  margin-right: 100px;
                }
              }
            }
          }
        }
      }
    }
  }

  .footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    bottom: 0;
    padding-bottom: 5px;

    .advanceDiv {
      width: 60%;
      display: flex;
      align-items: center;
      justify-content: center;

      button {
        width: 80%;
        color: #fff;
        padding: 15px 0;
        border-radius: 8px;
        border: none;
        outline: none;
        cursor: pointer;
        background: #023a86;
        transition: 0.3s;
      }
    }

    .regressDiv {
      width: 40%;
      display: flex;
      align-items: center;
      justify-content: center;

      button {
        width: 80%;
        color: #fff;
        padding: 15px 0;
        border-radius: 8px;
        border: none;
        outline: none;
        z-index: 99999;
        cursor: pointer;
        background: #afafaf;
      }
    }
  }
}
