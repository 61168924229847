.errorInput {
  position: absolute;
  top: 100%;
  right: 0;
}

.addressError {
  border-color: #f00 !important;
}

.prefixContainer {
  position: relative;

  .prefixText {
    position: absolute;
    right: 10px;
    bottom: 10px;

    span {
      font-size: 12px;
    }
  }
}

.rc-slider {
  margin-top: 10px;
}

.css-1wa3eu0-placeholder {
  font-size: 12px;
  margin: 0 !important;
  padding: 0 !important;
}

.infosButton {
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none !important;

  &:focus,
  &:hover,
  &:active {
    outline: none !important;
  }
}
