.backToStart {
  position: absolute;
  top: 50px;
  left: 50px;

  a {
    color: #023a86;
    margin-top: 10px;
    display: flex;
    align-items: center;
    font-size: 20px;

    p {
      font-weight: 600;
      margin: 0;
      padding: 0;
      margin-left: 10px;
    }

    &:hover {
      text-decoration: none;
      color: #000;
    }
  }
}
