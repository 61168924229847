@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

$primary: #023a86;
$secondary: #006a90;

::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif !important;
}

#root {
  width: 100vw;
  height: 100vh;
  display: flex;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

.user-select-none {
  user-select: none;
}

@import 'node_modules/bootstrap/scss/bootstrap';
@import 'views/formulario', 'views/start', 'views/end', 'components/paginator', 'components/input', 'components/autosuggest',
  'components/bottom_sheet', 'components/radio', 'components/modal', 'components/react_select', 'views/header',
  'views/recuperar_senha', './views/media_queries/queries', './views/media_queries/login', './views/media_queries/footer',
  './views/media_queries/forms', './global';

::placeholder {
  font-weight: 500 !important;
  color: gray('400') !important;
}
