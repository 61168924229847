.modal {
  padding: 0.5rem;

  .c_modal {
    height: 100%;
    margin: 0 auto;
    display: flex;
  }

  section {
    height: 100%;
  }

  .overflow {
    position: absolute;
    top: 1rem;
    left: 1rem;
    right: 1rem;
    bottom: 1rem;
    border-style: solid;
    border-top-width: 16vmax;
    border-left-width: 6vmax;
    border-right-width: 6vmax;
    border-bottom-width: 16vmax;
    border-color: #00000088;

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      border: 1px solid $secondary;
    }
  }
}
