@media only screen and (max-width: 600px) {
  .formStep {
    .controlForms {
      flex-direction: column;

      .leftSide {
        // display: none !important;
        width: 100% !important;
        padding: 0 !important;
        height: auto !important;

        .planToShow {
          display: none !important;
        }

        .ticketForm {
          margin-top: 0 !important;

          .codeInputDiv {
            button {
              width: 30% !important;
            }
          }
        }

        h1 {
          font-size: 2.5em !important;
        }
      }

      .rightSide {
        width: 100% !important;
        padding: 0 !important;
        height: auto !important;

        .successDiv {
          padding: 20px 10px !important;

          .successText {
            display: flex;
            flex-direction: column;

            svg {
              margin-bottom: 10px;
            }

            p {
              text-align: center;
            }
          }

          .successLink {
            flex-direction: column;

            p {
              text-align: center;
              margin-bottom: 10px !important;
            }
          }
        }

        div {
          h1 {
            margin-top: 30px;
          }
        }

        .successBtns {
          flex-direction: column;

          button {
            width: 100% !important;
            margin-bottom: 10px;
          }
        }

        .informationDiv {
          p {
            text-align: center;
          }

          svg {
            display: none;
          }
        }

        .controlPlansOffered {
          .plansOffered {
            .plan {
              width: 100% !important;
            }
          }
        }

        .formGroup {
          .especialGroup {
            .alertField {
              svg {
                display: none;
              }

              p {
                margin: 0 0 20px 0 !important;
                text-align: center;
              }
            }

            .especialFields {
              flex-direction: column;

              .fields {
                width: 100% !important;

                .especialLabel {
                  margin-top: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
}
