.c_paginator {
  &.wrapper {
    flex: 1;
    display: flex;
    overflow: hidden;
    flex-direction: column;
  }

  .wrapper-items {
    flex: 1;
    display: flex;
    // overflow: hidden;
    align-items: stretch;
  }

  .item {
    outline: none;
    width: 100%;
    max-height: 75vh;
    overflow-y: auto;
    overflow-x: hidden;
    flex-shrink: 0;
  }
  .item_celular {
    outline: none;
    width: 100%;
    min-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    flex-shrink: 0;
  }
  .item-um {
    outline: none;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    flex-shrink: 0;
  }
}
