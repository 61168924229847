@media only screen and (max-width: 600px) {
  .mainContainer {
    nav {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 0;

      svg {
        width: 30%;
      }
    }

    .bodyContainer {
      .cardCustom {
        border-radius: 10px;

        .leftSide {
          display: none;
        }

        .rightSide {
          width: 100% !important;
          padding: 30px 10px;

          .headerForm {
            margin: 25px 0;

            h1,
            p {
              text-align: center;
            }

            h1 {
              font-size: 2.5em;
            }

            p {
              font-size: 1.25em;
            }
          }

          .formGroup {
            .forgetPass {
              a {
                margin: 20px 0 0 0;
              }
            }
          }
        }
      }
    }
  }
}
