.label {
  width: 100%;
  font-size: 0.8rem;
}
.autosuggest-input {
  @extend .form-control;
  height: auto;
  display: flex;
  flex-direction: column;
  min-height: calc(1.5em + 0.75rem + 2px);
  border: none;
}
.autosuggest-input:focus {
  box-shadow: none;
}

.autosuggest {
  @extend .form-control;
  height: auto;
  display: flex;
  flex-direction: column;
  min-height: calc(1.5em + 0.75rem + 2px);
  border: none;

  .input {
    @extend .form-control;
    border: none;

    &:focus {
      border: none;
      box-shadow: none;
    }
  }

  .item {
    cursor: pointer;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
  }
}
.autosuggest:after {
  content: '\25BE';
  position: absolute;
  right: 20px;
  font-size: 19pt;
}
.autosuggest {
  position: relative;
}

.react-autosuggest__suggestions-container {
  border-radius: $border-radius;
  z-index: 20;
  box-shadow: initial;
  margin-top: -4px;
  background: red;
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  top: 100%;
}

.react-autosuggest__suggestions-list {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}

.react-autosuggest__suggestion {
  cursor: default;
  display: block;
  font-size: inherit;
  padding: 8px 12px;
  width: 100%;
  background: white !important;
  color: $secondary !important;

  &:hover,
  &:active {
    background: lighten(#e3e3e3, 10%) !important;
    color: black !important;
  }
}

.react-autosuggest__input--open {
  border: none !important;
}

.react-autosuggest__suggestions-container--open {
  border: none;
  border-top: initial;
  border-width: 1px !important;
}
